/* eslint-disable jsx-a11y/anchor-is-valid */
// Importing all resources
import "../home.css";
import "../montessori.css";
import "../responsive.css";
import hero from "../assets/primaryIntro.jpg";
import circles from "../assets/cirlces.png";
import momentoneImg from "../assets/priMomentOne.jpg";
import momenttwoImg from "../assets/priMomentTwo.jpg";
import momentThreeImg from "../assets/priMomentThree.jpg";
import momentFourImg from "../assets/priMomentFour.jpg";
import momentFiveImg from "../assets/priMomentFive.jpg";
import globe from "../assets/globe.png";
import book from "../assets/book.png";
import mask from "../assets/mask.svg";
import overviewImg from "../assets/primaryProgram.jpg";
import Footer from "../components/footer";
import Button from "../components/button";
import Navbartrial from "../components/navbartrial";
import { useEffect } from "react";

const IntroPage = () => {
  return (
    <>
      <article className="programsPage intro">
        <article>
          <h2>Where Education Meets Innovation</h2>
          <p>
            Nurturing Minds, Inspiring Futures, and forseeing a Passion for
            Lifelong Learning
          </p>
          <a href="/contact">
            <Button value="Schedule a Visit" />
          </a>
        </article>
        <article className="heroArticle toBeHidden">
          <img src={hero} alt="Three students" />
          <img src={circles} alt="Three students" className="circles" />
        </article>
        <img src={hero} alt="Three students" className="hide" />
      </article>
    </>
  );
};

const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Quest for Knowledge",
      imageSrc: book,
      imageAltText: "A book icon",
      text: "Quest for Knowledge is a dynamic and rigorous academic curriculum that fosters a lo",
      moreText:
        "ve for learning. We strive to provide a strong foundation in core subjects such as language arts, mathematics, science, and social studies. Through engaging lessons, project-based learning, and collaborative activities, we encourage critical thinking, problem-solving, and a growth mindset.",
      id: "one",
    },
    {
      heading: "Creative Explorations",
      imageSrc: mask,
      imageAltText: "Artistic masks",
      text: "In Creative Explorations, students unleash their creativity and innovative spirit. Through...",
      moreText:
        " visual and performing arts, music, and technology, we nurture self-expression and appreciation for different art forms. Students develop confidence in their abilities to think outside the box and use creativity to solve real-world challenges.",
      id: "two",
    },
    {
      heading: "Global Citizenship",
      imageSrc: globe,
      imageAltText: "The earth globe",
      text: "Global Citizenship focuses on cultivating empathy, cultural awareness, and a sense of",
      moreText:
        " responsibility towards the world. Students learn about different cultures, global issues, and environmental sustainability. Through community service projects and cross-cultural exchanges, we aim to create compassionate and socially conscious individuals.",
      id: "three",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  let hidden = true;
  function showMore(e) {
    e.preventDefault();
    if (hidden) {
      document
        .querySelector(`.simpleCard.${targetObject.id} .hide`)
        .classList.toggle("hide");
      hidden = false;
    } else {
      document
        .querySelector(`.simpleCard.${targetObject.id} span`)
        .classList.toggle("hide");
      hidden = true;
    }
  }
  return (
    <>
      <article className={`simpleCard ${targetObject.id}`}>
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>
            {targetObject.text}
            <span className="hide">{targetObject.moreText}</span>
          </p>
          <br />
          <a href="#" onClick={showMore}>
            Read More...
          </a>
        </article>
      </article>
    </>
  );
};

const Curriculum = () => {
  return (
    <>
      <article className="curriculum">
        <p className="descText">Curriculum and Approach</p>
        <h2>Nurturing Well-Rounded Development</h2>
        <p>
          Our Montessori curriculum fosters holistic growth, providing children
          with a rich educational experience that encompasses various subjects
          and areas of development.
        </p>
        <article className="simpleCards">
          <SimpleCard value="Quest for Knowledge" />
          <SimpleCard value="Creative Explorations" />
          <SimpleCard value="Global Citizenship" />
        </article>
      </article>
    </>
  );
};

const ProgramOverview = () => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel"),
      firstImg = carousel.querySelectorAll(".horizontalCard")[0],
      arrowIcons = document.querySelectorAll(".wrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft === scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft === 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft === scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
        -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="programOverview">
        <p className="descText">Program Overview</p>
        <h2>A Pathway to Academic Excellence and Personal Growth</h2>
        <p>
          Our Primary Program lays a strong foundation for your child's academic
          journey, nurturing their intellectual, emotional, and social
          development.
        </p>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
          <div className="carousel">
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Rigorous Academics</h3>
                <p>
                  Our curriculum blends core subjects with stimulating
                  educational experiences, cultivating critical thinking and
                  problem-solving abilities in students. From mathematics to
                  language arts, science to social studies.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Rigorous Academics</h3>
                <p>
                  Our curriculum blends core subjects with stimulating
                  educational experiences, cultivating critical thinking and
                  problem-solving abilities in students. From mathematics to
                  language arts, science to social studies.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Rigorous Academics</h3>
                <p>
                  Our curriculum blends core subjects with stimulating
                  educational experiences, cultivating critical thinking and
                  problem-solving abilities in students. From mathematics to
                  language arts, science to social studies.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Rigorous Academics</h3>
                <p>
                  Our curriculum blends core subjects with stimulating
                  educational experiences, cultivating critical thinking and
                  problem-solving abilities in students. From mathematics to
                  language arts, science to social studies.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Fourth Section / Inquiry Section
const InquirySection = () => {
  return (
    <>
      <article className="inquirySection">
        <article className="content">
          <h3>Your Child's Future Starts Here</h3>
          <p>Where Education Meets Inspiration and Endless Possibilities</p>
          <a href="/admission">
            <button className="pinkBtn">Inquire Today</button>
          </a>
        </article>
      </article>
    </>
  );
};

const Gallery = () => {
  return (
    <>
      <article className="montessoriMemoryMoments">
        <article className="rowOne">
          <article className="contentOne">
            <h3>Gallery</h3>
            <p>
              A Glimpse into the Vibrant and Engaging Experience of Our Students
            </p>
            <a href="/gallery">
              <Button value="See More" />
            </a>
          </article>
          <article className="contentTwo">
            <img
              src={momentoneImg}
              alt="A room with a green chalk board with various things on the shelf to the right of the board"
            />
          </article>
          <article className="contentThree toBeHidden">
            <img
              src={momenttwoImg}
              alt="A list of words"
              className="toBeHidden"
            />
          </article>
        </article>
        <article className="rowTwo toBeHidden">
          <article className="contentFour toBeHidden">
            <img src={momentThreeImg} alt="Little children in a race" />
          </article>
          <article className="contentFive toBeHidden">
            <img src={momentFourImg} alt="A boy playing with toys" />
          </article>
          <article className="contentSix toBeHidden">
            <img
              src={momentFiveImg}
              alt="An empty classroom"
              className="toBeHidden"
            />
          </article>
        </article>
      </article>
    </>
  );
};

export default function Primary() {
  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Curriculum />
      <InquirySection />
      <ProgramOverview />
      <Gallery />
      <Footer />
    </>
  );
}
