// Importing all resources
import "../home.css";
import "../montessori.css";
import "../responsive.css";
import hero from "../assets/kindergartenIntro.jpg";
import circles from "../assets/cirlces.png";
import momentoneImg from "../assets/kindMomentOne.png";
import momenttwoImg from "../assets/kindMomentTwo.png";
import momentThreeImg from "../assets/kindMomentThree.png";
import momentFourImg from "../assets/kindMomentFour.png";
import momentFiveImg from "../assets/kindMomentFive.png";
import mobileIntroHero from "../assets/introChild.png";
import mobileMissionImg from "../assets/missionMobile.png";
import leaf from "../assets/leaf.svg";
import palette from "../assets/palette.svg";
import globe from "../assets/globe.svg";
import overviewImg from "../assets/kindergartenProgram.jpg";
import Footer from "../components/footer";
import Badge from "../components/badge";
import Button from "../components/button";
import NavBar from "../components/navbar";
import Navbartrial from "../components/navbartrial";
import { useEffect } from "react";

const IntroPage = () => {
  return (
    <>
      <article className="programsPage intro">
        <article>
          <h2>Discover the Magic of Pre-School Program</h2>
          <p>Where Learning and Play Come Together</p>
          <a href="/contact">
            <Button value="Schedule a Visit" />
          </a>
        </article>
        <article className="heroArticle toBeHidden">
          <img src={hero} alt="Three students" />
          <img src={circles} alt="Three students" className="circles" />
        </article>
        <img src={hero} alt="A little girl painting" className="hide" />
      </article>
    </>
  );
};

const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Nature Explorers",
      imageSrc: leaf,
      imageAltText: "A leaf icon",
      text: "The Nature Explorers curriculum at WonderWorld Preschool encourages children",
      moreText:
        "to connect with the natural world around them. Through hands-on activities and outdoor adventures, we spark curiosity about plants, animals, and the environment. Our little explorers learn to appreciate nature's wonders while developing essential cognitive and motor skills.",
      id: "one",
    },
    {
      heading: "Artistic Dreamers",
      imageSrc: palette,
      imageAltText: "A painting palette icon",
      text: "Unleash the creativity of your child with our Artistic Dreamers curriculum. Through a wide",
      moreText:
        "array of visual arts, music, and movement activities, we inspire imagination and self-expression. Our young artists experiment with colors, shapes, and textures, nurturing their artistic talents and building confidence in their abilities.",
      id: "two",
    },
    {
      heading: "Global Citizens",
      imageSrc: globe,
      imageAltText: "The globe of the earth",
      text: "In the Global Citizens curriculum, we cultivate a sense of respect and understanding for diver",
      moreText:
        "se cultures and perspectives. Through stories, celebrations, and interactive activities, we foster a global mindset and teach empathy, tolerance, and cooperation. Our children become aware of their roles as responsible and compassionate members of the global community.",
      id: "three",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  let hidden = true;
  function showMore(e) {
    e.preventDefault();
    if (hidden) {
      document
        .querySelector(`.simpleCard.${targetObject.id} .hide`)
        .classList.toggle("hide");
      hidden = false;
    } else {
      document
        .querySelector(`.simpleCard.${targetObject.id} span`)
        .classList.toggle("hide");
      hidden = true;
    }
  }
  return (
    <>
      <article className={`simpleCard ${targetObject.id}`}>
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>
            {targetObject.text}
            <span className="hide">{targetObject.moreText}</span>
          </p>
          <br />
          <a href="#" onClick={showMore}>
            Read More...
          </a>
        </article>
      </article>
    </>
  );
};

const Curriculum = () => {
  return (
    <>
      <article className="curriculum">
        <p className="descText">Curriculum and Approach</p>
        <h2>Embracing Play-Based Learning</h2>
        <p>
          We believe in fostering a love for exploration, creativity, and
          building a strong foundation for future academic success.
        </p>
        <article className="simpleCards">
          <SimpleCard value="Nature Explorers" />
          <SimpleCard value="Artistic Dreamers" />
          <SimpleCard value="Global Citizens" />
        </article>
      </article>
    </>
  );
};

const ProgramOverview = () => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel"),
      firstImg = carousel.querySelectorAll(".horizontalCard")[0],
      arrowIcons = document.querySelectorAll(".wrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft == 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft == scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft == 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft == scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id == "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
          -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="programOverview">
        <p className="descText">Program Overview</p>
        <h2>A Joyful Path to Learning and Growth</h2>
        <p>
          Our Pre-School Program is thoughtfully designed to provide a nurturing
          and stimulating learning environment for children aged 4 to 6 years.
        </p>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
          <div className="carousel">
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Child-Centered Exploration</h3>
                <p>
                  Our educators follow the lead of the child, observing their
                  interests and curiosities. We design learning experiences that
                  build upon their passions, fostering a sense of ownership over
                  their education and a love for discovery.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Child-Centered Exploration</h3>
                <p>
                  Our educators follow the lead of the child, observing their
                  interests and curiosities. We design learning experiences that
                  build upon their passions, fostering a sense of ownership over
                  their education and a love for discovery.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Child-Centered Exploration</h3>
                <p>
                  Our educators follow the lead of the child, observing their
                  interests and curiosities. We design learning experiences that
                  build upon their passions, fostering a sense of ownership over
                  their education and a love for discovery.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Child-Centered Exploration</h3>
                <p>
                  Our educators follow the lead of the child, observing their
                  interests and curiosities. We design learning experiences that
                  build upon their passions, fostering a sense of ownership over
                  their education and a love for discovery.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Fourth Section / Inquiry Section
const InquirySection = () => {
  return (
    <>
      <article className="inquirySection">
        <article className="content">
          <h3>Your Child's Future Starts Here</h3>
          <p>Where Education Meets Inspiration and Endless Possibilities</p>
          <a href="/admission">
            <button className="pinkBtn">Inquire Today</button>
          </a>
        </article>
      </article>
    </>
  );
};

const Gallery = () => {
  return (
    <>
      <article className="montessoriMemoryMoments">
        <article className="rowOne">
          <article className="contentOne">
            <h3>Gallery</h3>
            <p>
              A Glimpse into the Vibrant and Engaging Experience of Our Students
            </p>
            <a href="/gallery">
              <Button value="See More" />
            </a>
          </article>
          <article className="contentTwo">
            <img
              src={momentoneImg}
              alt="A room with a green chalk board with various things on the shelf to the right of the board"
            />
          </article>
          <article className="contentThree toBeHidden">
            <img
              src={momenttwoImg}
              alt="A list of words"
              className="toBeHidden"
            />
          </article>
        </article>
        <article className="rowTwo toBeHidden">
          <article className="contentFour toBeHidden">
            <img src={momentThreeImg} alt="Little children in a race" />
          </article>
          <article className="contentFive toBeHidden">
            <img src={momentFourImg} alt="A boy playing with toys" />
          </article>
          <article className="contentSix toBeHidden">
            <img
              src={momentFiveImg}
              alt="An empty classroom"
              className="toBeHidden"
            />
          </article>
        </article>
      </article>
    </>
  );
};

export default function PreSchool() {
  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Curriculum />
      <InquirySection />
      <ProgramOverview />
      <Gallery />
      <Footer />
    </>
  );
}
