/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import gallery1 from "../../assets/gallery1.jpg";
import gallery2 from "../../assets/gallery2.jpg";
import gallery3 from "../../assets/gallery3.jpg";
import gallery4 from "../../assets/gallery4.jpg";
import gallery5 from "../../assets/gallery5.jpg";

function GalleryNursery() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery1} className="gallery-content1" />
      <img src={gallery4} />
      <img src={gallery5} />
      <img src={gallery2} />
      <img src={gallery3} />
    </section>
  );
}

export default GalleryNursery;
