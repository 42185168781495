import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home.js";
import Creche from "./pages/creche.js";
import PreSchool from "./pages/preschool.js";
import Nursery from "./pages/nursery.js";
import Primary from "./pages/primary.js";
import ErrorPage from "./pages/error.js";
import Facilities from "./pages/facilities.js";
import About from "./pages/about.js";
import Admission from "./pages/admission.js";
import AdmissionForm from "./pages/admissionForm.js";
import Contact from "./pages/contact.js";
// import Login from "./pages/login.js";
import SingleNew from "./components/SingleNew.js";
import NewsRootLayout from "./pages/news/newsRootLayout";
import AllNews from "./pages/news/allNews.js";
import LatestNews from "./pages/news/latestNews.js";
import EventsNews from "./pages/news/eventsNews.js";
import NewNews from "./pages/news/newNews.js";
import GalleryRootLayout from "./pages/gallery/galleryRootLayout";
import GalleryMontessori from "./pages/gallery/galleryMontessori.js";
import GalleryKindergarten from "./pages/gallery/galleryKindergarten.js";
import GalleryNusery from "./pages/gallery/galleryNursery.js";
import GalleryPrimary from "./pages/gallery/galleryPrimary.js";
import GalleryCampus from "./pages/gallery/galleryCampus";
import GalleryOutdoors from "./pages/gallery/galleryOutdoors";
import ScrollToTop from "./components/ScrollToTop.js";
const App = () => {
  return (
    <BrowserRouter>

      <ScrollToTop/>

      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/creche" element={<Creche />} />
        <Route path="/preschool" element={<PreSchool />} />
        <Route path="/nursery" element={<Nursery />} />
        <Route path="/primary" element={<Primary />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/gallery" element={<GalleryRootLayout />}>
          <Route index element={<GalleryMontessori />} />
          <Route path="kindergarten" element={<GalleryKindergarten />} />
          <Route path="nursery" element={<GalleryNusery />} />
          <Route path="primary" element={<GalleryPrimary />} />
          <Route path="campus" element={<GalleryCampus />} />
          <Route path="outdoors" element={<GalleryOutdoors />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/admission" element={<Admission />} />
        <Route path="/news" element={<NewsRootLayout />}>
          <Route index element={<AllNews />} />
          <Route path="latest" element={<LatestNews />} />
          <Route path="events" element={<EventsNews />} />
          <Route path="new" element={<NewNews />} />
          <Route path=":newId" element={<SingleNew />} />
        </Route>
        <Route path="/admissionform" element={<AdmissionForm />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
