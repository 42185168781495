import React from "react";
import Navbartrial from "../../components/navbartrial";
import Footer from "../../components/footer";
import { NavLink, Outlet } from "react-router-dom";
import "../../gallery.css";

function GalleryRootLayout() {
  return (
    <>
      <Navbartrial />
      <br />
      <br />
      <div className="gallery-container ">
        <section className="badge-wrapper-gallery">
          <NavLink
            to=""
            className={({ isActive }) => (isActive ? "active-btn" : "")}
            end
          >
            Montessori
          </NavLink>
          <NavLink
            to="kindergarten"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Kindergarten
          </NavLink>
          <NavLink
            to="nursery"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Nursery
          </NavLink>
          <NavLink
            to="primary"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Primary
          </NavLink>
          <NavLink
            to="campus"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Campus
          </NavLink>
          <NavLink
            to="outdoors"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Outdoors
          </NavLink>
        </section>
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default GalleryRootLayout;
