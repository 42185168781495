// Importing all resources
import "../home.css";
import "../montessori.css";
import "../responsive.css";
import hero from "../assets/nurseryIntro.jpg";
import circles from "../assets/cirlces.png";
import momentoneImg from "../assets/nurMomentOne.png";
import momenttwoImg from "../assets/nurMomentTwo.png";
import momentThreeImg from "../assets/nurMomentThree.png";
import momentFourImg from "../assets/nurMomentFour.png";
import momentFiveImg from "../assets/nurMomentFive.png";
import mobileIntroHero from "../assets/introChild.png";
import mobileMissionImg from "../assets/missionMobile.png";
import sparkles from "../assets/sparkles.svg";
import curious from "../assets/curious.svg";
import heart from "../assets/heart.svg";
import overviewImg from "../assets/nurseryProgram.jpg";
import Footer from "../components/footer";
import Badge from "../components/badge";
import Button from "../components/button";
import NavBar from "../components/navbar";
import Navbartrial from "../components/navbartrial";
import { useEffect } from "react";

const IntroPage = () => {
  return (
    <>
      <article className="programsPage intro">
        <article>
          <h2>Where Education Meets Innovation</h2>
          <p>
            Nurturing Minds, Inspiring Futures, and forseeing a Passion for
            Lifelong Learning
          </p>
          <a href="/contact">
            <Button value="Schedule a Visit" />
          </a>
        </article>
        <article className="heroArticle toBeHidden">
          <img src={hero} alt="Three students" />
          <img src={circles} alt="Three students" className="circles" />
        </article>
        <img src={hero} alt="A little girl painting" className="hide" />
      </article>
    </>
  );
};

const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Adventures in Imagination",
      imageSrc: sparkles,
      imageAltText: "Sparkling stars",
      text: "Adventures in Imagination is all about igniting the creative spark in our little learners...",
      moreText:
        ".Through imaginative play, storytelling, and interactive activities, we take children on exciting journeys to magical worlds. This curriculum stimulates language development, fosters social interactions, and nurtures cognitive skills through play-based learning.",
      id: "one",
    },
    {
      heading: "Tiny Explorers",
      imageSrc: curious,
      imageAltText: "A magnifying glass",
      text: "In Tiny Explorers, children embark on a journey of discovery and wonder. Through ha..",
      moreText:
        "nds-on experiences and sensory play, we encourage our little learners to explore their surroundings, from nature's textures to various materials. This curriculum enhances fine and gross motor skills, spatial awareness, and problem-solving abilities.",
      id: "two",
    },
    {
      heading: "Caring Hearts",
      imageSrc: heart,
      imageAltText: "Heart icon",
      text: "Caring Hearts focuses on nurturing emotional intelligence and social skills in our",
      moreText:
        " nursery children. We teach empathy, kindness, and respect through group activities, sharing circles, and gentle guidance. Children learn to express their feelings, develop friendships, and build a strong sense of community.",
      id: "three",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  let hidden = true;
  function showMore(e) {
    e.preventDefault();
    if (hidden) {
      document
        .querySelector(`.simpleCard.${targetObject.id} .hide`)
        .classList.toggle("hide");
      hidden = false;
    } else {
      document
        .querySelector(`.simpleCard.${targetObject.id} span`)
        .classList.toggle("hide");
      hidden = true;
    }
  }
  return (
    <>
      <article className={`simpleCard ${targetObject.id}`}>
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>
            {targetObject.text}
            <span className="hide">{targetObject.moreText}</span>
          </p>
          <br />
          <a href="#" onClick={showMore}>
            Read More...
          </a>
        </article>
      </article>
    </>
  );
};

const Curriculum = () => {
  return (
    <>
      <article className="curriculum">
        <p className="descText">Curriculum and Approach</p>
        <h2>Nurturing Well-Rounded Development</h2>
        <p>
          Our Montessori curriculum fosters holistic growth, providing children
          with a rich educational experience that encompasses various subjects
          and areas of development.
        </p>
        <article className="simpleCards">
          <SimpleCard value="Adventures in Imagination" />
          <SimpleCard value="Tiny Explorers" />
          <SimpleCard value="Caring Hearts" />
        </article>
      </article>
    </>
  );
};

const ProgramOverview = () => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel"),
      firstImg = carousel.querySelectorAll(".horizontalCard")[0],
      arrowIcons = document.querySelectorAll(".wrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft == 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft == scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft == 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft == scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id == "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
          -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="programOverview">
        <p className="descText">Program Overview</p>
        <h2>A Nurturing Beginning</h2>
        <p>
          Our Nursery Program provides a warm and caring environment that lays
          the foundation for your child's early learning journey.
        </p>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
          <div className="carousel">
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Playful Exploration</h3>
                <p>
                  Through play, your child engages in discovery and exploration,
                  developing essential motor skills and fostering cognitive
                  growth. Our nurturing educators guide these moments of
                  discovery, embracing the uniqueness of each child's journey
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Playful Exploration</h3>
                <p>
                  Through play, your child engages in discovery and exploration,
                  developing essential motor skills and fostering cognitive
                  growth. Our nurturing educators guide these moments of
                  discovery, embracing the uniqueness of each child's journey
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Playful Exploration</h3>
                <p>
                  Through play, your child engages in discovery and exploration,
                  developing essential motor skills and fostering cognitive
                  growth. Our nurturing educators guide these moments of
                  discovery, embracing the uniqueness of each child's journey
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Playful Exploration</h3>
                <p>
                  Through play, your child engages in discovery and exploration,
                  developing essential motor skills and fostering cognitive
                  growth. Our nurturing educators guide these moments of
                  discovery, embracing the uniqueness of each child's journey
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Fourth Section / Inquiry Section
const InquirySection = () => {
  return (
    <>
      <article className="inquirySection">
        <article className="content">
          <h3>Your Child's Future Starts Here</h3>
          <p>Where Education Meets Inspiration and Endless Possibilities</p>
          <a href="/admission">
            <button className="pinkBtn">Inquire Today</button>
          </a>
        </article>
      </article>
    </>
  );
};

const Gallery = () => {
  return (
    <>
      <article className="montessoriMemoryMoments">
        <article className="rowOne">
          <article className="contentOne">
            <h3>Gallery</h3>
            <p>
              A Glimpse into the Vibrant and Engaging Experience of Our Students
            </p>
            <a href="/gallery">
              <Button value="See More" />
            </a>
          </article>
          <article className="contentTwo">
            <img
              src={momentoneImg}
              alt="A room with a green chalk board with various things on the shelf to the right of the board"
            />
          </article>
          <article className="contentThree toBeHidden">
            <img
              src={momenttwoImg}
              alt="A list of words"
              className="toBeHidden"
            />
          </article>
        </article>
        <article className="rowTwo toBeHidden">
          <article className="contentFour toBeHidden">
            <img src={momentThreeImg} alt="Little children in a race" />
          </article>
          <article className="contentFive toBeHidden">
            <img src={momentFourImg} alt="A boy playing with toys" />
          </article>
          <article className="contentSix toBeHidden">
            <img
              src={momentFiveImg}
              alt="An empty classroom"
              className="toBeHidden"
            />
          </article>
        </article>
      </article>
    </>
  );
};

export default function Nursery() {
  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Curriculum />
      <InquirySection />
      <ProgramOverview />
      <Gallery />
      <Footer />
    </>
  );
}
