/* eslint-disable jsx-a11y/anchor-is-valid */
// Importing all resources
import "../home.css";
import "../admission.css";
import "../responsive.css";
import circles from "../assets/cirlces.png";
import admiVideo from "../assets/admiVideo.png";
import Footer from "../components/footer";
import passport from "../assets/mdi_passport.svg";
import health from "../assets/map_health.svg";
import academicHat from "../assets/mdi_academic-cap.svg";
import arrowOne from "../assets/Arrow 1.png";
import arrowTwo from "../assets/Arrow 2.png";
import Navbartrial from "../components/navbartrial";
import Header from "../components/header";
import { useEffect } from "react";

// Inquiry Section / Component
const InquirySection = () => {
  return (
    <article className="inquirySection">
      <article className="content">
        <h3>Your Child's Future Starts Here</h3>
        <p>Where Education Meets Inspiration and Endless Possibilities</p>
        <a href="/admissionform" className="pinkBtn">
          Inquire Today
        </a>
      </article>
    </article>
  );
};

// Simple Card Component
const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Birth Certificate",
      imageSrc: passport,
      imageAltText: "Birth Certificate icon",
      text: "The Nature Explorers curriculum at WonderWorld Preschool encourages children...",
    },
    {
      heading: "Health Information",
      imageSrc: health,
      imageAltText: "Health Information icon",
      text: "Unleash the creativity of your child with our Artistic Dreamers curriculum. Through a wide...",
    },
    {
      heading: "School Transcripts",
      imageSrc: academicHat,
      imageAltText: "School Transcripts",
      text: "In the Global Citizens curriculum, we cultivate a sense of respect and understanding for diver...",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  return (
    <>
      <article className="simpleCard">
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>{targetObject.text}</p>
          <br />
          <a href="#">Read More...</a>
        </article>
      </article>
    </>
  );
};

// Enrollment Requirement Section / Component
const EnrollmentReq = () => {
  return (
    <article className="curriculum-admission">
      <div>
        <h2>Enrolment Requirements</h2>
        <p>
          To ensure a smooth enrollment process, kindly prepare the following
          documents for submission
        </p>
      </div>

      <article className="simpleCards">
        <SimpleCard value="Birth Certificate" />
        <SimpleCard value="Health Information" />
        <SimpleCard value="School Transcripts" />
      </article>
    </article>
  );
};

// Testimonial Section / Component
const TestimonialSection = () => {
  useEffect(() => {
    const carousel = document.querySelector(".testimonialsWrapper .carousel"),
      firstImg = carousel.querySelectorAll(".content")[0],
      arrowIcons = document.querySelectorAll(".testimonialsWrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft === scrollWidth ? "none" : "block";
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
          -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="testimonialsWrapper">
        <div className="heading">
          <h2>Hear What Our Community Says</h2>
          <p>
            Read testimonials from parents, students, and educators about their
            experience at RPMS.
          </p>
        </div>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left arrow-hidden"></i>
          <div className="carousel">
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
            </article>
            <article className="content">
              <p>
                <cite>
                  "Choosing RPMS for our child was a game-changer! The caring
                  educators and engaging curriculum have sparked a love for
                  learning in our little one. We couldn't be happier with our
                  decision."
                </cite>
                Paula Lopez
                <br />
                <span>Parent</span>
              </p>
            </article>
            <i id="right" className="fa-solid fa-angle-right arrow-hidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Calling all the section / components in their respective order
export default function Admission() {
  return (
    <>
      <Navbartrial />
      <section className="intro">
        <Header
          title="Admission at RPMS"
          text="Join our inclusive community of young learners"
          btnText="Enroll Today"
          linkUrl="/admissionform"
        />
        <article className="heroArticle">
          <img src={admiVideo} alt="Little kid writing" />
          <img src={circles} alt="circles" className="circles" />
        </article>
      </section>
      <section className="admissionsteps-container">
        <section className="headText">
          <h2>Admission Process</h2>
          <p>
            At RPMS, we strive to make the admission process smooth and
            accessible. We welcome all prospective parents and students to
            embark on a journey of academic excellence and personal growth with
            us.
          </p>
        </section>
        <section className="admission-steps">
          <div className="text-container">
            <h2>01.Application Submission</h2>
            <p>
              Complete the online admission form with essential details about
              your child and family. We encourage you to provide accurate
              information to help us understand your child's needs and
              preferences better.
            </p>
          </div>
          <div className="image-container toBeHidden">
            <img src={arrowOne} alt="A line" />
          </div>
          <div className="image-container toBeHidden">
            <img src={arrowTwo} alt="An arrow pointing to the right" />
          </div>
          <div className="text-container">
            <h2>02.Meet and Greet</h2>
            <p>
              After receiving your application, our admission team will schedule
              a meet-and-greet session with you and your child. This informal
              meeting allows us to get to know each other and understand your
              child's interests and aspirations.
            </p>
          </div>
          <div className="text-container">
            <h2>
              03.Assessment <span>(If Applicable)</span>
            </h2>
            <p>
              Depending on the grade level, an age-appropriate assessment or
              interview may be conducted. Our assessments are designed to
              evaluate your child's readiness and determine the appropriate
              learning support they may need.
            </p>
          </div>
          <div className="image-container toBeHidden">
            <img src={arrowOne} alt="A line" />
          </div>
          <div className="image-container toBeHidden">
            <img src={arrowTwo} alt="An arrow pointing to the left" />
          </div>
          <div className="text-container">
            <h2>04.Enrollment Confirmation</h2>
            <p>
              Once your child's application is reviewed, you will receive an
              admission decision within a reasonable time frame. Upon
              acceptance, you will be provided with the necessary enrollment
              documents.
            </p>
          </div>
        </section>
      </section>
      <InquirySection />
      <EnrollmentReq />
      <TestimonialSection />
      <Footer />
    </>
  );
}
