/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import gallery1 from "../../assets/gallery1.jpg";
import gallery2 from "../../assets/gallery2.jpg";
import gallery3 from "../../assets/gallery3.jpg";
import gallery4 from "../../assets/gallery4.jpg";
import gallery5 from "../../assets/gallery5.jpg";
import gallery6 from "../../assets/gallery6.jpg";
import gallery7 from "../../assets/gallery7.jpg";
import gallery8 from "../../assets/gallery8.jpg";
import gallery9 from "../../assets/gallery9.jpg";
import gallery10 from "../../assets/gallery10.jpg";
import Button from "../../components/button";

function GalleryMontessori() {
  return (
    <>
      <section className="pics-gallery-wrapper">
        <img src={gallery1} className="gallery-content1" />
        <img src={gallery2} />
        <img src={gallery3} />
        <img src={gallery4} />
        <img src={gallery5} />
      </section>
      <section className="pics-gallery-wrapper">
        <img src={gallery6} />
        <img src={gallery7} />
        <img src={gallery8} className="gallery-content1" />
        <img src={gallery9} />
        <img src={gallery10} />
      </section>
      <div className="btnGallery">
        <Button value="Load More" />
      </div>
    </>
  );
}

export default GalleryMontessori;
