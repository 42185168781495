import "../card.css";

function Card(props) {
  return (
    <div className="card-component">
      <img className="card-img" src={props.img} alt={props.category} />
      <div className="card-body">
        <p>{props.category}</p>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>
    </div>
  );
}

export default Card;
