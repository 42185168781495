/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import gallery1 from "../../assets/gallery1.jpg";
import gallery2 from "../../assets/gallery2.jpg";
import gallery7 from "../../assets/gallery7.jpg";
import gallery8 from "../../assets/gallery8.jpg";
import gallery9 from "../../assets/gallery9.jpg";

function GalleryOutdoors() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery8} />
      <img src={gallery7} />
      <img src={gallery1} className="gallery-content1" />
      <img src={gallery9} />
      <img src={gallery2} />
    </section>
  );
}

export default GalleryOutdoors;
