import React from "react";
import Navbartrial from "../../components/navbartrial";
import Header from "../../components/header";
import { Outlet, NavLink } from "react-router-dom";
import Footer from "../../components/footer";
import "../../news.css";

function NewsRootLayout() {
  return (
    <>
      <Navbartrial />
      <div className="news-container">
        <Header
          subtitle="Latest News and Updates"
          text="Stay informed about the exciting happenings at RPMS."
        />
        <section className="badge-wrapper-news">
          <NavLink
            to=""
            className={({ isActive }) => (isActive ? "active-btn" : "")}
            end
          >
            All News
          </NavLink>
          <NavLink
            to="latest"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Latest
          </NavLink>
          <NavLink
            to="events"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            Events
          </NavLink>
          <NavLink
            to="new"
            className={({ isActive }) => (isActive ? "active-btn" : "")}
          >
            New
          </NavLink>
        </section>
        <Outlet />
      </div>{" "}
      <Footer />
    </>
  );
}

export default NewsRootLayout;
