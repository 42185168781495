/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import gallery4 from "../../assets/gallery4.jpg";
import gallery2 from "../../assets/gallery2.jpg";
import gallery8 from "../../assets/gallery8.jpg";
import gallery9 from "../../assets/gallery9.jpg";
import gallery10 from "../../assets/gallery10.jpg";

function GalleryPrimary() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery4} />
      <img src={gallery9} />
      <img src={gallery8} className="gallery-content1" />
      <img src={gallery2} />
      <img src={gallery10} />
    </section>
  );
}

export default GalleryPrimary;
