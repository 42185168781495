/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../dropdown.css";
import chevronDown from "../assets/chevron-down.svg";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    height:'auto',
    marginBottom:'1rem',
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20,height:0,marginBottom:'0rem',transition: { duration: 0.2 } }
};

export default function DropdownMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLap, setIsOpenLap] = useState(false);
  const [screenSize, setScreenSize] = useState(undefined)

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {

    const handleResize = () => setScreenSize(window.innerWidth)
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);

  }, [screenSize])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".menu-trigger") && isOpen) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  return (<>
    {screenSize<1000?<>
    <motion.nav
      initial={false}
      animate={isOpenLap ? "open" : "closed"}
      className="menu"
      style={{border:'none' , width:'100%'}}
    >
      <motion.button
        whileTap={{ scale: 0.97 }}
        onClick={() => setIsOpenLap(!isOpenLap)}
        style={{display:"flex" , border:'none' ,backgroundColor:'white' , margin:'auto' ,fontSize:'1.5rem' , color:'#082567' }}
        
      >
        Program
        <motion.div
          variants={{
            open: { rotate: 180 },
            closed: { rotate: 0 }
          }}
          transition={{ duration: 0.2 }}
          style={{ originY: 0.55 , marginLeft:'5px' }}
        >
          <svg width="15" height="15" viewBox="0 0 20 20">
            <path d="M0 7 L 20 7 L 10 16" />
          </svg>
        </motion.div>
      </motion.button>
      <motion.ul
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          closed: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 0.3
            }
          }
        }}
        style={{ pointerEvents: isOpenLap ? "auto" : "none" , display:"block" , textAlign:"center" , marginTop:'1rem' }}
      >
        <motion.li variants={itemVariants} > <Link to="/creche">Creche</Link></motion.li>
        <motion.li variants={itemVariants} > <Link to="/preschool">Pre-School</Link> </motion.li>
        <motion.li variants={itemVariants} > <Link to="/nursery">Nursery</Link></motion.li>
        <motion.li variants={itemVariants} > <Link to="/primary">Primary</Link></motion.li>
        <motion.li variants={itemVariants} > </motion.li>
      </motion.ul>
    </motion.nav>
    </>:<>

    <div className="menu-trigger">
      <div onClick={toggleDropdown} style={{ display:"flex" , width:'100%' , margin:'auto'}} >
      <p>Program</p>
      <img src={chevronDown} style={{marginLeft:'4px'}} />
      </div>
      {isOpen && (
        <motion.div
        initial={{opacity:0 , y:'30px'}}
        animate={{opacity:isOpen?1:0 , y:isOpen?'0px':'30px'}}
        transition={{duration:0.5}}
        >
        <div className="dropMenu">
          <ul>
            <li className={`drop-item ${isOpen ? "open" : ""}`}>
              <Link to="/creche">Creche</Link>
            </li>
            <li className="drop-item">
              <Link to="/preschool">Pre-School</Link>
            </li>
            <li className="drop-item">
              <Link to="/nursery">Nursery</Link>
            </li>
            <li className="drop-item">
              <Link to="/primary">Primary</Link>
            </li>
          </ul>
        </div>
        </motion.div>
      )}
    </div>

    </>}
    </>
  );
}


