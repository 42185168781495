import React from "react";
import Card from "../../components/card";
import news1 from "../../assets/newspage1.png";
import news2 from "../../assets/newspage2.png";
import news3 from "../../assets/newspage3.png";
import news4 from "../../assets/newspage4.png";
import news5 from "../../assets/newspage5.png";
import news6 from "../../assets/newspage6.png";
import { Link } from "react-router-dom";

const newsInfo = [
  {
    image: news5,
    category: "Academics",
    title: "Inspiring Minds: Guest Speaker Series",
    text: "Renowned author [Guest Speaker's Name] shared valuable insights with our students. They discussed the power of storytelling and motivated students to embrace the world of literature and imaginati",
  },
  {
    image: news1,
    category: "Event",
    title: "Exploring the Wonders of Science",
    text: "Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery.",
  },
  {
    image: news6,
    category: "Community",
    title: "Giving Back: Community Service Day",
    text: "Our students spent the day giving back to the community through various service activities. From cleaning local parks to visiting nursing homes, they learned the importance of compassion and making a p",
  },
  {
    image: news4,
    category: "Cultural",
    title: "International Cultural Fair",
    text: "The school came alive with colors, flavors, and traditions from around the world. Our students proudly presented their cultural heritage through performances, food stalls, and interactive displays.",
  },
];

function NewNews() {
  return (
    <section className="news-wrapper">
      {newsInfo.map((news, idx) => (
        <Link to="h1h1">
          <Card
            key={`${idx}-${news.title}`}
            id={`${idx}-${news.title}`}
            img={news.image}
            title={news.title}
            category={news.category}
            text={news.text}
          />
        </Link>
      ))}
    </section>
  );
}

export default NewNews;
