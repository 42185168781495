import Button from "../components/button";
import Footer from "../components/footer";
import Header from "../components/header";
import Navbartrial from "../components/navbartrial";
import circles from "../assets/circlesadmission1.png";
import "../admissionForm.css";

function AdmissionForm() {
  return (
    <>
      <Navbartrial />
      <div className="admissionForm-container">
        <img src={circles} alt="Three students" className="circles" />
        <Header
          subtitle="Admission Form"
          text="Please complete the following form to initiate the admission process for your child"
        />

        <form className="admission-form">
          <section>
            <h3>Child Information</h3>
            <div className="form-row">
              <div className="input-data">
                <label>Name of Child</label>
                <input type="text" placeholder="e.g John" />
              </div>
              <div className="input-data">
                <label>Date of Birth</label>
                <input type="date" />
              </div>
            </div>

            <div className="form-row">
              <div className="input-data">
                <label>Gender</label>
                <input type="text" placeholder="e.g Male" />
              </div>
              <div className="input-data">
                <label>Nationality</label>
                <input type="text" placeholder="e.g English" />
              </div>
            </div>

            <div className="form-row">
              <div className="input-data">
                <label>Current Adress</label>
                <input type="text" placeholder="e.g str. Clean nr. 40" />
              </div>
            </div>
          </section>

          <section>
            <h3>Parent/Guardian Information</h3>
            <div className="form-row">
              <div className="input-data">
                <label>Name of Parent</label>
                <input type="text" placeholder="e.g John" />
              </div>
              <div className="input-data">
                <label>Relationship to Child</label>
                <input type="text" placeholder="e.g Mother" />
              </div>
            </div>
            <div className="form-row">
              <div className="input-data">
                <label>Contact Number</label>
                <input type="text" placeholder="e.g 000-000-0000" />
              </div>
              <div className="input-data">
                <label>Email</label>
                <input type="email" placeholder="e.g john@gmail.com" />
              </div>
            </div>
          </section>
          <section>
            <h3>Educational Background</h3>
            <div className="form-row">
              <div className="input-data">
                <label>Current School (if applicable)</label>
                <input type="text" placeholder="e.g St. Vincent" />
              </div>
              <div className="input-data">
                <label>Grade Level Applying for</label>
                <input type="text" placeholder="e.g Creche" />
              </div>
            </div>
          </section>
          <section>
            <h3>Additional Information</h3>
            <div className="input-data textarea">
              <label>
                Does your child have any medical conditions or allergies we
                should be aware of? If yes, please provide details.
              </label>
              <textarea placeholder="e.g Has diabetes"></textarea>
            </div>

            <div className="input-data textarea">
              <label>
                Please briefly describe your child's interests, hobbies, and any
                unique talents.
              </label>
              <textarea placeholder="e.g likes toys and play in the sand"></textarea>
            </div>
          </section>
          <div>
            <Button value="Submit" />
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default AdmissionForm;
