// Importing all resources
import "../home.css";
import "../responsive.css";
import "../facilities.css";
import circles from "../assets/cirlces.png";
import momentoneImg from "../assets/nurMomentOne.png";
import momenttwoImg from "../assets/kindMomentTwo.png";
import momentThreeImg from "../assets/kindMomentThree.png";
import momentFourImg from "../assets/kindMomentFour.png";
import momentFiveImg from "../assets/kindMomentFive.png";
import mobileIntroHero from "../assets/mobileFacilities.jpg";
import mobileMissionImg from "../assets/missionMobile.jpeg";
import facilitiesTop from "../assets/facilitiesTop.jpg";
import classroom from "../assets/facilitiesClassroom.png";
import playareas from "../assets/facilitiesPlayareas.png";
import computerlab from "../assets/facilitiesComputerlab.jpg";
import Footer from "../components/footer";
import Button from "../components/button";
import Navbartrial from "../components/navbartrial";
import Header from "../components/header";

const IntroPage = () => {
  return (
    <>
      <article className="intro facilities">
        <Header
          title="Explore Our Exceptional Learning Facilities"
          text="Fostering a Safe, Vibrant, and Engaging Environment"
          btnText="Take a Tour"
          linkUrl="/gallery"
        />
        <article className="heroArticle toBeHidden">
          <img src={facilitiesTop} alt="Three students" />
          <img src={circles} alt="Three students" className="circles" />
        </article>
        <img
          src={mobileIntroHero}
          alt="A little girl painting"
          className="hide"
        />
      </article>
    </>
  );
};

const Classrooms = () => {
  return (
    <>
      <article className="classrooms">
        <article className="contentOne">
          <span className="categories">Classrooms</span>
          <h2>Inspiring Learning Spaces</h2>
          <p>
            Our classrooms are thoughtfully designed to spark creativity and
            foster an immersive learning experience. Equipped with modern
            teaching aids and technology, these spaces provide the perfect
            setting for educators to engage students in interactive and
            collaborative learning.
          </p>
        </article>
        <article className="contentTwo toBeHidden">
          <img src={classroom} alt="Dummy Alt Text" />
        </article>
      </article>
      <article className="hide missionArticle">
        <img
          src={classroom}
          alt="Few children and two adults sitting in a circle playing"
        />
      </article>
    </>
  );
};
const PlayAreas = () => {
  return (
    <>
      <article className="playAreas">
        <article className="contentTwo toBeHidden">
          <img src={mobileMissionImg} alt="Dummy Alt Text" />
        </article>
        <article className="contentOne">
          <span className="categories">Play Areas</span>
          <h2>A World of Play and Discovery</h2>
          <p>
            Our play areas are more than just recreational spaces; they are
            dynamic environments where children develop essential motor skills,
            build social connections, and explore their imaginations. Safety
            measures ensure carefree play, fostering healthy physical and
            emotional development.
          </p>
        </article>
      </article>
      <article className="hide missionArticle">
        <img
          src={mobileMissionImg}
          alt="Few children and two adults sitting in a circle playing"
        />
      </article>
    </>
  );
};
const ComputerLab = () => {
  return (
    <>
      <article className="computerLab">
        <article className="contentOne">
          <span className="categories">Computer Lab</span>
          <h2>Learning in the Digital Age</h2>
          <p>
            Our state-of-the-art computer lab is equipped with the latest
            technology and software, providing students with hands-on
            experiences in coding, programming, and digital literacy. From
            interactive learning programs to multimedia projects, students have
            the opportunity to unleash their creativity and problem-solving
            skills.
          </p>
        </article>
        <article className="contentTwo toBeHidden">
          <img src={computerlab} alt="Dummy Alt Text" />
        </article>
      </article>
      <article className="hide missionArticle">
        <img
          src={computerlab}
          alt="Few children and two adults sitting in a circle playing"
        />
      </article>
    </>
  );
};
const Gallery = () => {
  return (
    <>
      <article className="montessoriMemoryMoments gallerySection">
        <article className="rowOne">
          <article className="contentOne">
            <h3>Gallery</h3>
            <p>
              A Glimpse into the Vibrant and Engaging Experience of Our Students
            </p>
            <a href="/gallery">
              <Button value="See More" />
            </a>
          </article>
          <article className="contentTwo">
            <img src={momentoneImg} alt="An empty classroom" />
          </article>
          <article className="contentThree toBeHidden">
            <img
              src={momenttwoImg}
              alt="A table with books, pencils and alphabet toys on it"
            />
          </article>
        </article>
        <article className="rowTwo toBeHidden">
          <article className="contentFour toBeHidden">
            <img
              src={momentThreeImg}
              alt="An empty children's game field with game inscriptions on the grass"
            />
          </article>
          <article className="contentFive toBeHidden">
            <img
              src={momentFourImg}
              alt="Two people walking on a path with buildings to their right"
            />
          </article>
          <article className="contentSix toBeHidden">
            <img src={momentFiveImg} alt="A person writng" />
          </article>
        </article>
      </article>
    </>
  );
};
export default function Facilities() {
  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Classrooms />
      <PlayAreas />
      <ComputerLab />
      <Gallery />
      <Footer />
    </>
  );
}
