/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import gallery3 from "../../assets/gallery3.jpg";
import gallery4 from "../../assets/gallery4.jpg";
import gallery6 from "../../assets/gallery6.jpg";
import gallery8 from "../../assets/gallery8.jpg";
import gallery9 from "../../assets/gallery9.jpg";

function GalleryCampus() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery8} className="gallery-content1" />
      <img src={gallery6} />
      <img src={gallery9} />
      <img src={gallery4} />
      <img src={gallery3} />
    </section>
  );
}

export default GalleryCampus;
